<template>
  <v-img
    style="background-color: #FFFFFF; background-image: linear-gradient(1100deg, #FFFFFF 0%, #F5F5F5 100%);"
  >
    <base-section
      id="features"
    >
      <v-responsive
        class="mx-auto"
        max-width="1150"
      >
        <v-container fluid>
          <v-row
            justify="start"
            align="end"
          >
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 12 : 6"
            >
              <div
                style="font-size: 22px;"
                class="font-weight-bold primary--text mb-6"
              >
                Integrated platform
              </div>
              <h2>
                and intelligent document handling solution
              </h2>
              <div
                style="font-size: 18px"
                class="mt-4 dark grey--text text--darken-1"
              >
                We help professionals get rid of repetitive work and put
                the time saved into the important aspects of their business.
                Our product enables
                <a
                  class="link"
                >
                  lawyers
                </a>,
                <a
                  class="link"
                >
                  notaries
                </a>,
                <a
                  class="link"
                >
                  accountants,
                </a>
                <a
                  class="link"
                >
                  HR
                </a>, and
                <a
                  class="link"
                >
                  anyone who works with documents
                </a>
                to automate their processes.
              </div>
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.mobile ? 12 : 6"
            >
              <div
                v-if="$vuetify.breakpoint.mdAndUp"
                class="empty-box"
              /><br v-if="$vuetify.breakpoint.mdAndUp">
              <div
                style="font-size: 18px"
                class="mt-4 dark grey--text text--darken-1"
              >
                We also help
                <a
                  class="link"
                >
                  law firms and companies
                </a>
                manage their affairs, clients, determine access to information and much more with an intuitive easy-to-use workflow that requires no adaptation or acquisition of new skills.
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-responsive>
    </base-section>
  </v-img>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({}),
  }
</script>

<style scoped>
.link {
 color: #234E79;
 text-decoration: none;
 cursor: text;
 font-weight: bold;
}
/* .link:hover {
  color: black;
} */
.empty-box {
  position: relative;
  height: 30%;
  width: 100%;
  left: 20%;
  top: 20%;
}
</style>
